import { createSlice, createAsyncThunk,createAction } from "@reduxjs/toolkit";
import axios from "axios";
import baseUrl from "../../utils/config";
const resetEditAction = createAction("aboutUs/reset");

//add action
export const addAboutAction = createAsyncThunk(
  "aboutUs/add",
  async (about, { rejectWithValue, getState, dispatch }) => {

    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
        "Content-Type": "application/json",
      },
    };
    //http call
    try {
      const { data } = await axios.post(
        `${baseUrl}/aboutusData`,
        about,
        config
      );
      dispatch(fetchAboutAction());
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAboutAction = createAsyncThunk(
  "aboutUs/details",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/aboutusData`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchDetailAction = createAsyncThunk(
  "aboutUs/detail",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/aboutusData/${id}`
      );
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const updateAboutAction = createAsyncThunk(
    "aboutUs/update",
    async ({ data ,id}, { rejectWithValue, getState, dispatch }) => {
      // Get user token
      const user = getState()?.users;
      const { userAuth } = user;
      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
          "Content-Type": "application/json",
        },
 
      };
  
      // HTTP call
      try {
        const { data:details } = await axios.put(
          `${baseUrl}/aboutusData/${id}`,
          {data}, // Use the updated values from the form
          config
        );
  
        // Dispatch
        dispatch(resetEditAction());
        dispatch(fetchAboutAction());
        return details;
      } catch (error) {
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error?.response?.data);
      }
    }
  );

//delete
export const deleteAboutAction = createAsyncThunk(
  "aboutUs/delete",
  async (id, { getState, rejectWithValue, dispatch }) => {
    const user = getState().users.userAuth;
    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    };
    try {
      const { data } = await axios.delete(
        `${baseUrl}/aboutusData/${id}`,
        config
      );
      dispatch(fetchAboutAction());
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  }
);





const UPDATE_ROW_ORDER = 'aboutUs/updateRowOrder';

// Define the action creator function for updating row order
export const updateRowOrderAction = createAsyncThunk(
  UPDATE_ROW_ORDER,
  async (newRowOrder, { rejectWithValue,dispatch }) => {
    try {
      // Make an API call to update the row order on the server
      const response = await axios.put( `${baseUrl}/aboutusData/`, newRowOrder);
      
      // Return the updated row order data if successful
      dispatch(fetchAboutAction());
      return response.data;
    } catch (error) {
      // Handle error if the API call fails
      return rejectWithValue(error.response.data);
    }
  }
);
export const resetState = createAction("Reset_all");
const initialState = {
  appErr: false,
  loading: false,
  isSuccess: false,
};
const aboutUsSlices = createSlice({
  name: "aboutUs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addAboutAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addAboutAction.fulfilled, (state, action) => {
      state.loading = false;
      state.added = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(addAboutAction.rejected, (state, action) => {
    //  console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //all details
    builder.addCase(fetchAboutAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAboutAction.fulfilled, (state, action) => {
      state.loading = false;
      state.Details = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchAboutAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //delete
    builder.addCase(deleteAboutAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAboutAction.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      // Update  state after deletion if needed
    });
    builder.addCase(deleteAboutAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action.payload?.message;
      state.serverErr = action.error?.message;
      state.isSuccess = false;
    });

    //update
    builder.addCase(updateAboutAction.pending, (state, action) => {
      state.loading = true;
   
    });
    builder.addCase(resetEditAction, (state, action) => {
        state.isEdited = true;
      });
    builder.addCase(updateAboutAction.fulfilled, (state, action) => {
        state.Updated = action?.payload;
      state.loading = false;
      
      console.log(action.payload);
      state.isUpdated = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateAboutAction.rejected, (state, action) => {
      console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    // details
    builder.addCase(fetchDetailAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchDetailAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      console.log(action.payload);
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchDetailAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });

    builder.addCase(resetState, () => initialState);
    builder.addCase(updateRowOrderAction.fulfilled, (state, action) => {
      // Update the row order in the state with the data returned from the server
      state.rowOrder = action.payload;
    });
  },
});

export default aboutUsSlices.reducer;