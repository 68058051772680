import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";
import baseUrl from "../../utils/config";
const resetEditAction = createAction("sliders/reset");

//add action
export const addSlidersAction = createAsyncThunk(
  "sliders/add",
  async (sliders, { rejectWithValue, getState, dispatch }) => {
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    //http call
    try {
      const { data } = await axios.post(`${baseUrl}/sliders/`, sliders, config);
      dispatch(fetchSlidersAction());
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchSlidersAction = createAsyncThunk(
  "sliders/details",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/sliders`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchSliderAction = createAsyncThunk(
  "sliders/detail",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/sliders/${id}`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const updateSlidersAction = createAsyncThunk(
  "sliders/update",
  async ({ data, id }, { rejectWithValue, getState, dispatch }) => {
    // Get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    // HTTP call
    try {
      const { data: sliders } = await axios.put(
        `${baseUrl}/sliders/${id}`,
        data, // Use the updated values from the form
        config
      );

      // Dispatch
      dispatch(resetEditAction());
      dispatch(fetchSlidersAction());
      return sliders;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//delete
export const deleteSlidersAction = createAsyncThunk(
  "sliders/delete",
  async (id, { getState, rejectWithValue, dispatch }) => {
    const user = getState().users.userAuth;
    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    };
    try {
      const { data } = await axios.delete(`${baseUrl}/sliders/${id}`, config);
      dispatch(fetchSlidersAction());
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  }
);
export const resetState = createAction("Reset_all");
const initialState = {
  appErr: false,
  loading: false,
  isSuccess: false,
};
const slidersSlices = createSlice({
  name: "sliders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addSlidersAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addSlidersAction.fulfilled, (state, action) => {
      state.loading = false;
      state.added = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(addSlidersAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //category details
    builder.addCase(fetchSlidersAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchSlidersAction.fulfilled, (state, action) => {
      state.loading = false;
      state.Details = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchSlidersAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //delete
    builder.addCase(deleteSlidersAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSlidersAction.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      // Update categories state after deletion if needed
    });
    builder.addCase(deleteSlidersAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action.payload?.message;
      state.serverErr = action.error?.message;
      state.isSuccess = false;
    });

    //update
    builder.addCase(updateSlidersAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetEditAction, (state, action) => {
      state.isEdited = true;
    });
    builder.addCase(updateSlidersAction.fulfilled, (state, action) => {
      state.Updated = action?.payload;
      state.loading = false;

      state.isUpdated = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateSlidersAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    // details
    builder.addCase(fetchSliderAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchSliderAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;

      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchSliderAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });

    builder.addCase(resetState, () => initialState);
  },
});

export default slidersSlices.reducer;
