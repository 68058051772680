import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { themeSettings } from "./theme";
import Login from "./scenes/login";
import Layout from "./scenes/layout";
import Dashboard from "./scenes/dashboard";
//import { action as logoutAction } from "./scenes/logout/logout";
import Categories from "./scenes/categories";
import Allergens from "./scenes/allergens";
import Feedbacks from "./scenes/feedbacks";
import Contacts from "./scenes/contacts";
import AboutUs from "./scenes/aboutUs";
import PromoCodes from "./scenes/promoCode";
import Colors from "./scenes/colors";
import Privacy from "./scenes/privacy";
import AddPrivacy from "./scenes/privacy/addPrivacy";
import EditPrivacy from "./scenes/privacy/editPrivacy";
import Terms from "./scenes/terms";
import AddTerms from "./scenes/terms/addTerms";
import EditTerms from "./scenes/terms/editTerms";
import Maps from "./scenes/maps";
import Sliders from "./scenes/sliders";
import Orders from "./scenes/orders";
import NavItems from "./scenes/navItems";
import Products from "./scenes/items";
import Units from "./scenes/unit";
import Extras from "./scenes/extras";
import Admins from "./scenes/admin";
import IngredientsExtras from "./scenes/indredientsExtras";
import Settings from "./scenes/settings";
import Translation from "./scenes/translation";
//import FullFeaturedCrudGrid from "./scenes/navItems/test";
import WorkingHours from "./scenes/workingHours";
import Sorting from "./scenes/items/sorting";
//import Test from "./scenes/test";
import { ToastContainer } from "react-toastify";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const auth = useSelector((state) => state.users.userAuth);

  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Login />} />
            {auth && (
              <Route path="/dashboard" element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route path="categories" element=<Categories /> />

                <Route path="allergens" element=<Allergens /> />

                <Route path="feedbacks" element=<Feedbacks /> />
                <Route path="contacts" element=<Contacts /> />

                <Route path="workingHours" element=<WorkingHours /> />

                <Route path="aboutUs" element=<AboutUs /> />

                <Route path="promoCode" element=<PromoCodes /> />
                <Route path="colors" element=<Colors /> />
                <Route path="privacy" element=<Privacy /> />
                <Route path="addPrivacy" element=<AddPrivacy /> />
                <Route path="editPrivacy/:id" element=<EditPrivacy /> />
                <Route path="terms" element=<Terms /> />
                <Route path="addTerms" element=<AddTerms /> />
                <Route path="editTerms/:id" element=<EditTerms /> />
                <Route path="maps" element=<Maps /> />
                <Route path="sliders" element=<Sliders /> />
                <Route path="orders" element=<Orders /> />
                {/*<Route path="order/:id" element=<ViewOrders /> />*/}
                <Route path="navItems" element=<NavItems /> />
                <Route path="items" element=<Products /> />
                <Route path="sorting" element=<Sorting /> />
                <Route path="data" element=<Units /> />
                <Route path="extras" element=<Extras /> />
                <Route path="admins" element=<Admins /> />
                <Route path="ingredientsExtras" element=<IngredientsExtras /> />
                <Route path="settings" element=<Settings /> />
                <Route path="translation" element=<Translation /> />
              </Route>
            )}
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
