import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";
import baseUrl from "../../utils/config";
const resetEditAction = createAction("items/reset");

//add action
export const addItemsAction = createAsyncThunk(
  "items/add",
  async (items, { rejectWithValue, getState, dispatch }) => {
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    //http call
    try {
      const { data } = await axios.post(`${baseUrl}/itemsData/`, items, config);
      dispatch(fetchItemsAction());
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchItemsAction = createAsyncThunk(
  "items/details",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/itemsData`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchItemAction = createAsyncThunk(
  "items/detail",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/itemsData/${id}`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const updateItemsAction = createAsyncThunk(
  "items/update",
  async ({ data, id }, { rejectWithValue, getState, dispatch }) => {
    // Get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    // HTTP call
    try {
      const { data: contact } = await axios.put(
        `${baseUrl}/itemsData/${id}`,
        data, // Use the updated values from the form
        config
      );

      // Dispatch
      dispatch(resetEditAction());
      dispatch(fetchItemsAction());
      return contact;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//delete
export const deleteItemsAction = createAsyncThunk(
  "items/delete",
  async (id, { getState, rejectWithValue, dispatch }) => {
    const user = getState().users.userAuth;
    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    };
    try {
      const { data } = await axios.delete(`${baseUrl}/itemsData/${id}`, config);
      dispatch(fetchItemsAction());
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  }
);

const UPDATE_ROW_ORDER = "items/updateRowOrder";

// Define the action creator function for updating row order
export const updateItemOrderAction = createAsyncThunk(
  UPDATE_ROW_ORDER,
  async (newRowOrder, { rejectWithValue, dispatch, getState }) => {
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      // Make an API call to update the row order on the server
      const response = await axios.put(
        `${baseUrl}/items/`,
        newRowOrder,
        config
      );

      // Return the updated row order data if successful
      dispatch(fetchItemsAction());
      return response.data;
    } catch (error) {
      // Handle error if the API call fails
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetState = createAction("Reset_all");
const initialState = {
  appErr: false,
  loading: false,
  isSuccess: false,
};
const itemsSlices = createSlice({
  name: "items",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addItemsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addItemsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.added = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(addItemsAction.rejected, (state, action) => {
      console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //category details
    builder.addCase(fetchItemsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchItemsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.Details = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchItemsAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //delete
    builder.addCase(deleteItemsAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteItemsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      // Update  state after deletion if needed
    });
    builder.addCase(deleteItemsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action.payload?.message;
      state.serverErr = action.error?.message;
      state.isSuccess = false;
    });

    //update
    builder.addCase(updateItemsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetEditAction, (state, action) => {
      state.isEdited = true;
    });
    builder.addCase(updateItemsAction.fulfilled, (state, action) => {
      state.Updated = action?.payload;
      state.loading = false;
      state.isUpdated = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateItemsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    // details
    builder.addCase(fetchItemAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchItemAction.fulfilled, (state, action) => {
      state.loading = false;
      state.item = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchItemAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });

    builder.addCase(resetState, () => initialState);
    builder.addCase(updateItemOrderAction.fulfilled, (state, action) => {
      // Update the row order in the state with the data returned from the server
      state.rowOrder = action.payload;
    });
  },
});

export default itemsSlices.reducer;
