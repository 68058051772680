import { createSlice, createAsyncThunk,createAction } from "@reduxjs/toolkit";
import axios from "axios";
import baseUrl from "../../utils/config";
const resetEditAction = createAction("navItems/reset");

//add action
export const addDetailsAction = createAsyncThunk(
  "navItems/add",
  async (navItems, { rejectWithValue, getState, dispatch }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //http call
    try {
      const { data } = await axios.post(
        `${baseUrl}/navItems/`,
        navItems,
        config
      );
      dispatch(fetchDetailsAction());
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchDetailsAction = createAsyncThunk(
  "navItems/details",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/navItems`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchDetailAction = createAsyncThunk(
  "navItems/detail",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/navItems/${id}`
      );
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const updateDetailsAction = createAsyncThunk(
    "navItems/update",
    async ({ data ,id}, { rejectWithValue, getState, dispatch }) => {
      // Get user token
      const user = getState()?.users;
      const { userAuth } = user;
      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
          "Content-Type": "application/json",
        },
 
      };
  
      // HTTP call
      try {
        const { data:navItems } = await axios.put(
          `${baseUrl}/navItems/${id}`,
          {data}, // Use the updated values from the form
          config
        );
  
        // Dispatch
        dispatch(resetEditAction());
        dispatch(fetchDetailsAction());
        return navItems;
      } catch (error) {
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error?.response?.data);
      }
    }
  );

//delete
export const deleteDetailsAction = createAsyncThunk(
  "navItems/delete",
  async (id, { getState, rejectWithValue, dispatch }) => {
    const user = getState().users.userAuth;
    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    };
    try {
      const { data } = await axios.delete(
        `${baseUrl}/navItems/${id}`,
        config
      );
      dispatch(fetchDetailsAction());
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  }
);
export const resetState = createAction("Reset_all");
const initialState = {
  appErr: false,
  loading: false,
  isSuccess: false,
};
const navItemsSlices = createSlice({
  name: "navItems",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addDetailsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addDetailsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.added = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(addDetailsAction.rejected, (state, action) => {
    //  console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //category details
    builder.addCase(fetchDetailsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchDetailsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.Details = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchDetailsAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //delete
    builder.addCase(deleteDetailsAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteDetailsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      // Update  state after deletion if needed
    });
    builder.addCase(deleteDetailsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action.payload?.message;
      state.serverErr = action.error?.message;
      state.isSuccess = false;
    });

    //update
    builder.addCase(updateDetailsAction.pending, (state, action) => {
      state.loading = true;
   
    });
    builder.addCase(resetEditAction, (state, action) => {
        state.isEdited = true;
      });
    builder.addCase(updateDetailsAction.fulfilled, (state, action) => {
        state.Updated = action?.payload;
      state.loading = false;
      
      console.log(action.payload);
      state.isUpdated = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateDetailsAction.rejected, (state, action) => {
      console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //category details
    builder.addCase(fetchDetailAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchDetailAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      console.log(action.payload);
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchDetailAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });

    builder.addCase(resetState, () => initialState);
  },
});

export default navItemsSlices.reducer;