import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import baseUrl from "../../utils/config";

export const fetchFeedbacksAction = createAsyncThunk(
  "feedbacks/details",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/feedback`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//delete
export const deleteFeedbacksAction = createAsyncThunk(
  "feedbacks/delete",
  async (id, { getState, rejectWithValue, dispatch }) => {
    const user = getState().users.userAuth;
    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    };
    try {
      const { data } = await axios.delete(`${baseUrl}/feedback/${id}`, config);
      dispatch(fetchFeedbacksAction());
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  }
);

const UPDATE_ROW_ORDER = "feedback/updateRowOrder";

// Define the action creator function for updating row order
export const updateRowOrderAction = createAsyncThunk(
  UPDATE_ROW_ORDER,
  async (newRowOrder, { rejectWithValue, dispatch, getState }) => {
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      // Make an API call to update the row order on the server
      const response = await axios.put(
        `${baseUrl}/feedback/`,
        newRowOrder,
        config
      );

      // Return the updated row order data if successful
      dispatch(fetchFeedbacksAction());
      return response.data;
    } catch (error) {
      // Handle error if the API call fails
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  appErr: false,
  loading: false,
  isSuccess: false,
};
const feedbacksSlices = createSlice({
  name: "feedbacks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //category details
    builder.addCase(fetchFeedbacksAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchFeedbacksAction.fulfilled, (state, action) => {
      state.loading = false;
      state.Details = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchFeedbacksAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //delete
    builder.addCase(deleteFeedbacksAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteFeedbacksAction.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccess = true;
    });
    builder.addCase(deleteFeedbacksAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action.payload?.message;
      state.serverErr = action.error?.message;
      state.isSuccess = false;
    });
    builder.addCase(updateRowOrderAction.fulfilled, (state, action) => {
      // Update the row order in the state with the data returned from the server
      state.rowOrder = action.payload;
    });
  },
});

export default feedbacksSlices.reducer;
