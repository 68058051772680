import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";
import baseUrl from "../../utils/config";
const resetEditAction = createAction("ingredientsExtras/reset");

//add action
export const addDetailsAction = createAsyncThunk(
  "ingredientsExtras/add",
  async (extras, { rejectWithValue, getState, dispatch }) => {
    const user = getState().users.userAuth;
    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    //http call
    try {
      const { data } = await axios.post(
        `${baseUrl}/ingredientsExtras`,
        extras,
        config
      );
      dispatch(fetchDataAction());
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchDataAction = createAsyncThunk(
  "ingredientsExtras/details",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/ingredientsExtras`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchDetailAction = createAsyncThunk(
  "ingredientsExtras/detail",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`${baseUrl}/ingredientsExtras/${id}`);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const updateDetailsAction = createAsyncThunk(
  "ingredientsExtras/update",
  async ({ data, id }, { rejectWithValue, getState, dispatch }) => {
    // Get user token
    const user = getState().users.userAuth;
    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    // HTTP call
    try {
      const { data: extras } = await axios.put(
        `${baseUrl}/ingredientsExtras/${id}`,
        { data }, // Use the updated values from the form
        config
      );

      // Dispatch
      dispatch(resetEditAction());
      dispatch(fetchDataAction());
      return extras;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//delete
export const deleteDetailsAction = createAsyncThunk(
  "ingredientsExtras/delete",
  async (id, { getState, rejectWithValue, dispatch }) => {
    const user = getState().users.userAuth;
    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    };
    try {
      const { data } = await axios.delete(
        `${baseUrl}/ingredientsExtras/${id}`,
        config
      );
      dispatch(fetchDataAction());
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  }
);

const UPDATE_ROW_ORDER = "ingredientsExtras/updateRowOrder";

// Define the action creator function for updating row order
export const updateRowOrderAction = createAsyncThunk(
  UPDATE_ROW_ORDER,
  async (newRowOrder, { rejectWithValue, dispatch, getState }) => {
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      // Make an API call to update the row order on the server
      const response = await axios.put(
        `${baseUrl}/ingredientsExtras/`,
        newRowOrder,
        config
      );

      // Return the updated row order data if successful
      dispatch(fetchDataAction());
      return response.data;
    } catch (error) {
      // Handle error if the API call fails
      return rejectWithValue(error.response.data);
    }
  }
);
export const resetState = createAction("Reset_all");
const initialState = {
  appErr: false,
  loading: false,
  isSuccess: false,
};
const ingredientsExtrasSlices = createSlice({
  name: "ingredientsExtras",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addDetailsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addDetailsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.added = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(addDetailsAction.rejected, (state, action) => {
      //  console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //category details
    builder.addCase(fetchDataAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchDataAction.fulfilled, (state, action) => {
      state.loading = false;
      state.Details = action?.payload;
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchDataAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });
    //delete
    builder.addCase(deleteDetailsAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteDetailsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      // Update  state after deletion if needed
    });
    builder.addCase(deleteDetailsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action.payload?.message;
      state.serverErr = action.error?.message;
      state.isSuccess = false;
    });

    //update
    builder.addCase(updateDetailsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetEditAction, (state, action) => {
      state.isEdited = true;
    });
    builder.addCase(updateDetailsAction.fulfilled, (state, action) => {
      state.Updated = action?.payload;
      state.loading = false;

      console.log(action.payload);
      state.isUpdated = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateDetailsAction.rejected, (state, action) => {
      console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //category details
    builder.addCase(fetchDetailAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchDetailAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      console.log(action.payload);
      state.appErr = false;
      state.serverErr = undefined;
      state.isSuccess = true;
    });
    builder.addCase(fetchDetailAction.rejected, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
      state.isSuccess = false;
    });

    builder.addCase(resetState, () => initialState);
    builder.addCase(updateRowOrderAction.fulfilled, (state, action) => {
      // Update the row order in the state with the data returned from the server
      state.rowOrder = action.payload;
    });
  },
});

export default ingredientsExtrasSlices.reducer;
